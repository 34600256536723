import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ServerStatus extends Component {
    static defaultProps = {
        statusCode: null
    }

    componentWillMount() {
        if (this.props.statusCode && this.props.staticContext) {
            this.props.staticContext.status = this.props.statusCode
        }
    }

    render() {
        return null
    }
}

export default withRouter(ServerStatus)
