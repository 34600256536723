import React from 'react'
import ServerStatus from './ServerStatus.jsx'
import ScrollToTop from './ScrollToTop.jsx'

const NotFound = () => (
    <div className="not-found">
        <ScrollToTop />
        <ServerStatus statusCode={404} />
        <h1>404</h1>
    </div>
)

export default NotFound
