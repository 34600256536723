import React from "react";
import Scroller from "../components/Scroller.jsx";

const LandingVideo = () => {
	return (
		<div className="landing-container" id="landing">
			<div className="video-container">
				<div className="video-inner">
					<video
						className="video"
						src="/video/FLAT22.mp4"
						autoPlay
						muted
						loop
						playsInline
					></video>
				</div>
			</div>
			<div className="lan-scroller">
				<Scroller scrollTo="about" />
			</div>
		</div>
	);
};

export default LandingVideo;
