const setBaseName = (langString) => {
    switch (langString) {
        case 'en':
            return 'en'
        default:
            return 'hr'
    }
}

const getBaseName = (langString) => {
    const baseName = langString === 'hr' ? '' : `/${langString}`
    return baseName
}

export default setBaseName
export { setBaseName }
export { getBaseName }

