import React from "react";
import { connect } from "react-redux";
import Imageloader from "alt-imageloader";

const AboutContainer = ({ langData }) => {
    return (
        <div className="section-spacing">
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <h1 className="about-title">{langData.title}</h1>
                    </div>
                    <div className="col-md-7 col-md-offset-1">
                        <p className="paragraph about-paragraph">
                            {langData.text1}
                        </p>
                        <p className="paragraph about-paragraph">
                            {langData.text2}
                        </p>
                        <p className="paragraph about-paragraph">
                            {langData.text3}
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className="about-team">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-md-offset-1">
							<h2 className="medium-title-n">{langData.subtitle}</h2>
						</div>
						<div className="col-md-11 col-md-offset-1">
							<div className="about-team-member">
								<div className="about-team-member-image">
									<Imageloader src="/images/team/afrodita.jpg" isImage />
								</div>
								<div className="about-team-member-text">
									<h3 className="small-title-n">Afrodita Lekaj</h3>
									{langData.team_member_2.map((text, index) => (
										<p className="paragraph about-paragraph" key={index}>
											{text}
										</p>
									))}
								</div>
							</div>
							<div className="about-team-member">
								<div className="about-team-member-image">
									<Imageloader src="/images/team/dino.jpg" isImage />
								</div>
								<div className="about-team-member-text">
									<h3 className="small-title-n">Dino Kos</h3>
									{langData.team_member_1.map((text, index) => (
										<p className="paragraph about-paragraph" key={index}>
											{text}
										</p>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    langData: state.langData.about,
});

export default connect(mapStateToProps)(AboutContainer);
