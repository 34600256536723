import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const SOCIAL_ITEMS = [
    // {
    //     link: "https://twitter.com/AionProdukcija",
    //     img: "twitter.svg",
    //     img2: "twitter_active.svg",
    // },
    {
        link: "https://www.instagram.com/flat22production/",
        img: "instagram.svg",
        img2: "instagram_active.svg",
    },
    {
        link: "https://www.facebook.com/profile.php?id=100083440964607",
        img: "facebook.svg",
        img2: "facebook_active.svg",
    },
    {
        link: "https://www.youtube.com/channel/UCbp5Y0HzhWXCKB-RRgjHYAw",
        img: "youtube.svg",
        img2: "youtube_active.svg",
    },
];

const NewFooter = (props) => {
    const navItems = props.langData.navItems.map((val, key) => (
        <HashLink className="nfooter-nav-item" to={val.link} key={key} smooth>
            <span className="nfooter-nav-item__title">{val.title}</span>
        </HashLink>
    ));

    const socialItems = SOCIAL_ITEMS.map((val, key) => (
        <a
            className="nfooter-soc-item"
            href={val.link}
            rel="noopener noreferrer"
            target="_blank"
            key={key}
        >
            <img
                className="nfooter-soc-item__img"
                src={`/images/icons/${val.img}`}
                alt="soc"
            />
            <img
                className="nfooter-soc-item__img--colored"
                src={`/images/icons/${val.img2}`}
                alt="soc"
            />
        </a>
    ));

    return (
        <div className="nfooter">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 hidden-sm hidden-xs">
                        <span className="nfooter-copyright">
                            {props.langData.copyright}
                        </span>
                    </div>
                    <div className="col-md-4 hidden-sm hidden-xs">
                        <div className="nfooter-nav">{navItems}</div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="nfooter-soc">{socialItems}</div>
                    </div>
                    <div className="col-sm-12 col-xs-12 visible-sm visible-xs">
                        <span className="nfooter-copyright">
                            {props.langData.copyright}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    langData: state.langData.footer,
    socialItems: state.langData.socialItems,
});

export default withRouter(connect(mapStateToProps)(NewFooter));
