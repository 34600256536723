import referencesData from '../data/referencesData'

const referencesDataStarted = () => ({
    type: 'REFERENCES_DATA_STARTED'
})

const referencesDataDone = data => ({
    type: 'REFERENCES_DATA_ENDED',
    payload: data
})

const referencesDataError = () => ({
    type: 'REFERENCES_DATA_ERROR'
})

const basename = process.env.BASE_URL || ''

export default () => (dispatch) => {
    dispatch(referencesDataStarted())

    dispatch(referencesDataDone(referencesData))

    // fetch(`${basename}/api/v1/references`)
    //     .then(response => response.json())
    //     .then((result) => {
    //         dispatch(referencesDataDone(result.d))
    //     })
    //     .catch(() => {
    //         dispatch(referencesDataError())
    //     })
}
