import React, { Component } from "react";
import VisbilitySensor from "react-visibility-sensor";

class AnimatedTitle extends Component {
	state = {
		animate1: false,
		animate2: false,
	};

	static defaultProps = {
		title: "",
	};

	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState(
				{
					animate1: true,
				},
				() => {
					setTimeout(() => {
						this.setState({
							animate2: true,
						});
					}, 650);
				}
			);
		}
	};

	render() {
		const animate1 = this.state.animate1 ? "anim-title--animate1" : "";
		const animate2 = this.state.animate2 ? "anim-title--animate2" : "";

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className={`anim-title ${animate1} ${animate2}`}>
							<VisbilitySensor
								onChange={this.onVisibilityChange}
								offset={{ bottom: 100 }}
							>
								<div className="title-border">
									<h1 className="big-title title-border__text">
										{this.props.title}
									</h1>
								</div>
							</VisbilitySensor>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AnimatedTitle;
