import React from 'react'
import ScrollToTop from '../utils/ScrollToTop.jsx'
import ReferenceContainer from '../containers/ReferenceContainer.jsx'


const ReferenceRoute = () => (
    <div>
        <ScrollToTop />
        <ReferenceContainer />
    </div>
)

export default ReferenceRoute
