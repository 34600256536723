import { combineReducers } from 'redux'
import activeLang from './activeLang'
import langData from './langData'
import allReferencesData from './allReferencesData'
import oneReferenceData from './oneReferenceData'

const combinedReducers = combineReducers({
    activeLang,
    langData,
    allReferencesData,
    oneReferenceData
})

export default combinedReducers
