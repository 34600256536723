import React from "react";
import { Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import NotFound from "./utils/NotFound.jsx";
import LandingRoute from "./routes/LandingRoute.jsx";
import ReferenceRoute from "./routes/ReferenceRoute.jsx";
import OtherReferencesRoute from "./routes/OtherReferencesRoute.jsx";
import ContactRoute from "./routes/ContactRoute.jsx";
import AboutRoute from "./routes/AboutRoute.jsx";
import NewFooter from "./components/NewFooter.jsx";
import MobileHeader from "./components/MobileHeader.jsx";

const Routes = (props) => (
	<Provider store={props.store}>
		<div>
			<MobileHeader />
			<Switch>
				<Route exact path="/" component={LandingRoute} />
				<Route path="/reference/:id" component={ReferenceRoute} />
				<Route path="/other-references" component={OtherReferencesRoute} />
				<Route path="/about" component={AboutRoute} />
				<Route component={NotFound} />
			</Switch>
			<NewFooter />
		</div>
	</Provider>
);

export default Routes;
