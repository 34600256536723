import React, { Component } from 'react'
import emailValidChecker from '../utils/emailValidChecker'
import Input from './Input.jsx'
import NewInput from './NewInput.jsx'
import Button from './Button.jsx'

class ContactForm extends Component {
    state = {
        mailObj: {
            name: '',
            email: '',
            message: ''
        },
        messageId: -1
    }

    handleMailInput = (key, e) => {
        const newObject = Object.assign({}, this.state.mailObj)
        newObject[key] = e.target.value
        this.setState({
            mailObj: newObject
        })
    }

    sendMail = () => {
        this.setState({
            buttonLoadingClass: 'button-loading'
        })

        // if (this.state.mailObj.name && this.state.mailObj.email && this.state.mailObj.message) {
        //     if (emailValidChecker(this.state.mailObj.email)) {
        //         return sendMail(this.state.mailObj)
        //             .then(() => {
        //                 setTimeout(() => {
        //                     this.setState({
        //                         messageId: 0,
        //                         buttonLoadingClass: ''
        //                     })
        //                 }, 500)
        //             })
        //             .catch(() => {
        //                 setTimeout(() => {
        //                     this.setState({
        //                         messageId: 1,
        //                         buttonLoadingClass: ''
        //                     })
        //                 }, 500)
        //             })
        //     }
        //     setTimeout(() => {
        //         this.setState({
        //             messageId: 3,
        //             buttonLoadingClass: ''
        //         })
        //     }, 500)
        // } else {
        //     setTimeout(() => {
        //         this.setState({
        //             messageId: 2,
        //             buttonLoadingClass: ''
        //         })
        //     }, 500)
        // }
        // return () => {}
    }

    render() {
        let message = null
        if (this.state.messageId !== -1) {
            message = (
                <span className="cf-message">
                    {this.props.langData.messages[this.state.messageId]}
                </span>
            )
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-12">
                        <div className="cf-inputs">
                            <NewInput
                                placeholder={this.props.langData.namePlaceH}
                                inputName="name"
                                isActive={!!this.state.mailObj.name}
                                onChangeHandler={this.handleMailInput.bind(this, 'name')}
                            />
                            <NewInput
                                placeholder={this.props.langData.emailPlaceH}
                                inputName="email"
                                isActive={!!this.state.mailObj.email}
                                onChangeHandler={this.handleMailInput.bind(this, 'email')}
                            />
                            <NewInput
                                placeholder={this.props.langData.messagePlaceH}
                                inputName="message"
                                isActive={!!this.state.mailObj.message}
                                onChangeHandler={this.handleMailInput.bind(this, 'message')}
                            />
                            <div className="cf-send-button" >
                                <Button
                                    title={this.props.langData.btnTitle}
                                    handler={this.sendMail}
                                    buttonLoadingClass={this.state.buttonLoadingClass}
                                />
                                <div className="cf-message-container">
                                    {message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactForm
