import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Imageloader from "alt-imageloader";
import ReferenceText from "../components/ReferenceText.jsx";
import ReferenceGallery from "../components/ReferenceGallery.jsx";
import Scroller from "../components/Scroller.jsx";
import referencesData from "../data/referencesData";

class ReferenceContainer extends Component {
    static defaultProps = {
        activeLang: "hr",
    };

    state = {
        animate: false,
    };

    componentDidMount() {
        this.startAnim();
    }

    startAnim = () => {
        setTimeout(() => {
            this.setState({
                animate: true,
            });
        }, 400);
    };

    render() {
        const data = referencesData[this.props.match.params.id];

        return (
            <div className="rc">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${
                        data[`naziv_${this.props.activeLang}`]
                    } | FLAT22 produkcija`}</title>
                    <link
                        href={`https://flat22production.com/reference/${this.props.match.params.id}`}
                    />
                    <meta
                        name="og:title"
                        content={data[`naziv_${this.props.activeLang}`]}
                    />
                    <meta
                        name="og:description"
                        content={data[`tekst_1_${this.props.activeLang}`]}
                    />
                    <meta
                        property="og:image"
                        content={`https://flat22production.com/backgrounds/${data.naslovna_slika}`}
                    />
                </Helmet>
                <div className="rc-top">
                    <video
                        className="video"
                        src={`/video/${data.main_video}`}
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                    {/* <Imageloader
						className="rc-top-image"
						src={`/images/backgrounds/${data.glavna_slika}`}
						isImage
					/> */}
                    <div
                        className={`rc-top-scroller ${
                            this.state.animate ? "rc-top-scroller--animate" : ""
                        }`}
                    >
                        <div className="rc-top-scroller-inner">
                            <Scroller scrollTo="description" />
                        </div>
                    </div>
                </div>
                <div className="rc-desc" id="description">
                    <div className="container-fluid rc-container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <h1 className="small-title-n rc-project-title">
									{data[`naziv_${this.props.activeLang}`]}
								</h1> */}
                                <h1 className="mega-big-title rc-desc-title">
                                    {data[`naziv_${this.props.activeLang}`]}
                                </h1>
                            </div>
                        </div>
                        <div className="row rc-desc-row">
                            <div className="col-md-4 col-md-offset-2 col-sm-12 col-xs-12">
                                <ReferenceText
                                    data={data}
                                    num={1}
                                    activeLang={this.props.activeLang}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <div className="rc-img">
                                    <div className="rc-img-inner">
                                        <Imageloader
                                            src={`/images/backgrounds/${data.slika_1}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row rc-desc-row">
                            <div className="col-sm-12 col-xs-12 visible-sm visible-xs">
                                <ReferenceText
                                    data={data}
                                    num={2}
                                    activeLang={this.props.activeLang}
                                />
                            </div>
                            <div className="col-md-4 col-md-offset-2 col-sm-12 col-xs-12">
                                <div className="rc-img rc-img--no-margin">
                                    <div className="rc-img-inner">
                                        <Imageloader
                                            src={`/images/backgrounds/${data.slika_2}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 hidden-sm hidden-xs">
                                <ReferenceText
                                    data={data}
                                    num={2}
                                    activeLang={this.props.activeLang}
                                />
                            </div>
                        </div>
                        <div className="row rc-desc-row">
                            <div className="col-md-4 col-md-offset-2 col-sm-12 col-xs-12">
                                <ReferenceText
                                    data={data}
                                    num={3}
                                    activeLang={this.props.activeLang}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <div className="rc-img">
                                    <div className="rc-img-inner">
                                        <Imageloader
                                            src={`/images/backgrounds/${data.slika_3}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data[`zakljucak_${this.props.activeLang}`] ? (
                            <div className="row rc-desc-row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <p className="paragraph rc-conclusion">
                                        {
                                            data[
                                                `zakljucak_${this.props.activeLang}`
                                            ]
                                        }
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="section-spacing">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="rc-youtube">
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={data.video_src}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-spacing">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="rc-poster">
                                    <Imageloader
                                        src={`/images/backgrounds/${data.poster}`}
                                        isImage
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.activeLang,
    langData: state.langData.referenceContainer,
});

export default withRouter(connect(mapStateToProps)(ReferenceContainer));
