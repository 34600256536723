export default (state = [], action) => {
    switch (action.type) {
        case 'REFERENCES_DATA_ENDED':
            return action.payload
        case 'RESET_REFERENCES_DATA':
            return []
        default:
            return state
    }
}
