import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ImageLoader from "alt-imageloader";
import VisibilitySensor from "react-visibility-sensor";
import AnimatedTitle from "../components/AnimatedTitle.jsx";
import Button from "../components/Button.jsx";
import allReferencesDataAction from "../actions/allReferencesDataAction";

class References extends Component {
	static defaultProps = {
		data: [],
	};

	state = {
		animate: false,
	};

	componentDidMount() {
		if (this.props.data.length < 1) {
			this.props.allReferencesDataAction();
		}
	}

	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState({
				animate: true,
			});
		}
	};

	render() {
		const referenceItems = this.props.data
			? this.props.data.map((val, key) => {
					const animClass = `reference-item--anim-${key}`;

					return (
						<div className="col-md-6 col-sm-6 col-xs-12" key={key}>
							<Link to={`reference/${val.id}`}>
								<div className={`reference-item ${animClass}`}>
									<ImageLoader
										className="reference-item--img"
										src={`/images/backgrounds/${val.naslovna_slika}`}
									/>
									<div className="reference-item-overlay">
										<div className="reference-item-text">
											<p className="small-title reference-item-text__title">
												{val[`naziv_${this.props.activeLang}`]}
											</p>
											<p className="small-title">
												{val[`tip_${this.props.activeLang}`]}
											</p>
										</div>
									</div>
								</div>
							</Link>
						</div>
					);
			  })
			: null;

		return (
			<div id="reference" className="references">
				<AnimatedTitle title={this.props.langData.title} />
				<VisibilitySensor
					onChange={this.onVisibilityChange}
					offset={{ top: 200 }}
					partialVisibility
				>
					<div className="references-container">
						<div className="container-fluid">
							<div
								className={`row ${
									this.state.animate ? "references-row--animate" : ""
								}`}
							>
								{referenceItems}
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="references-button">
										<Button
											title={this.props.langData.btnTitle}
											handler={() =>
												this.props.history.push(this.props.langData.btnLink)
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</VisibilitySensor>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	langData: state.langData.references,
	data: state.allReferencesData,
	activeLang: state.activeLang,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			allReferencesDataAction,
		},
		dispatch
	);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(References)
);
