import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, matchPath } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import get from "lodash/get";
import reportWebVitals from "./reportWebVitals";
import Routes from "./Routes.js";
import combinedReducers from "./reducers/index";
import { getBaseName, setBaseName } from "./utils/baseName";
import langData from "./data/langData";
import "./styles/application.scss";

const match = matchPath(window.location.pathname, {
	path: "/:lang",
	exact: false,
});

const activeLang = setBaseName(get(match, "params.lang"));

const initialState = {
	activeLang,
	langData: langData[activeLang],
};

const store = createStore(
	combinedReducers,
	initialState,
	applyMiddleware(thunk)
);

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter basename={getBaseName(initialState.activeLang)}>
			<Routes store={store} />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
