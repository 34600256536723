import React from "react";
import { Helmet } from "react-helmet";
import ScrollToTop from "../utils/ScrollToTop.jsx";
import AboutContainer from "../containers/AboutContainer.jsx";

const AboutRoute = () => (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>About | FLAT22 production</title>
            <meta
                name="og:description"
                content="FLAT22 je video i film produkcija sa sjedištem u Rijeci."
            />
            <meta
                property="og:image"
                content="https://flat22production.com/backgrounds/flat22_large_logo.jpg"
            />
            <link href="https://flat22production.com/about" />
        </Helmet>
        <ScrollToTop />
        <AboutContainer />
    </div>
);

export default AboutRoute;
