import React from 'react'
import { connect } from 'react-redux'
import scrollTo from '../utils/scrollTo'

const Scroller = props => (
    <div
        className="scroller-container"
        onClick={() => { scrollTo(props.scrollTo) }}
    >
        <div className="scroller"></div>
        <p className="scroller-title">
            {props.langData.title}
        </p>
    </div>
)

Scroller.defaultProps = {
    scrollTo: ''
}

const mapStateToProps = state => ({
    langData: state.langData.scroller
})

export default connect(mapStateToProps)(Scroller)
