export default (overflow) => {
    if (typeof document !== 'undefined') { // ssr check
        const body = document.body
        if (overflow) {
            body.style.overflowY = 'hidden'
        } else {
            body.style.overflowY = 'auto'
        }
    }
}
