import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";

class ReferenceText extends Component {
	static defaultProps = {
		data: {},
		num: 1,
		activeLang: "hr",
	};

	state = {
		animate: false,
	};

	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState({
				animate: true,
			});
		}
	};

	render() {
		const rtNum3Class = this.props.num === 3 ? "rt-number--3" : "";
		const rtMarginClass = this.props.num === 2 ? "rt-margin" : "";

		const textItems = this.props.data[
			`tekst_${this.props.num}_${this.props.activeLang}`
		].map((val, key) => (
			<p className="paragraph rt-text__paragraph" key={key}>
				{val}
			</p>
		));

		return (
			<VisibilitySensor
				onChange={this.onVisibilityChange}
				offset={{ top: 100 }}
			>
				<div
					className={`rt ${rtMarginClass} ${
						this.state.animate ? "rt--animate" : ""
					}`}
				>
					<div className={`rt-number ${rtNum3Class}`}>
						<p className="rt-number__num">{`0${this.props.num}`}</p>
						{this.props.num === 2 ? (
							<div className="rt-number-dots hidden-sm hidden-xs">
								.................................................................................................................................
							</div>
						) : null}
					</div>
					<div className="rt-text">
						<h3 className="medium-title-n rt-text__title">
							{
								this.props.data[
									`naslov_${this.props.num}_${this.props.activeLang}`
								]
							}
						</h3>
						{textItems}
					</div>
				</div>
			</VisibilitySensor>
		);
	}
}

export default ReferenceText;
