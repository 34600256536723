import React from "react";
import { connect } from "react-redux";
import AnimatedTitle from "../components/AnimatedTitle.jsx";
import ContactForm from "../components/ContactForm.jsx";

const ContactContainer = (props) => {
    return (
        <div className="contact-section">
            <AnimatedTitle title={props.langData.title} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="con-info">
                            <div className="con-info-text">
                                {/* <div className="con-info-text__item">
                                    <a
                                        href="https://www.google.com/maps/place/Ul.+Franje+%C4%8Candeka+23B,+51000,+Rijeka/@45.3394113,14.4118134,17z/data=!3m1!4b1!4m5!3m4!1s0x4764a6d2e88781fd:0x377cca3ee2d991c0!8m2!3d45.3394076!4d14.4140021"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <span className="paragraph">{props.langData.address}</span>
                                    </a>
                                </div> */}
                                <div className="con-info-text__item">
                                    <a href="tel:+385911776747">
                                        <span className="paragraph">
                                            {props.langData.phoneNum}
                                        </span>
                                    </a>
                                </div>
                                <div className="con-info-text__item">
                                    <a href="mailto:flat22production@gmail.com">
                                        <span className="paragraph">
                                            {props.langData.email}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ContactForm langData={props.langData} /> */}
        </div>
    );
};
const mapStateToProps = (state) => ({
    langData: state.langData.contact,
});

export default connect(mapStateToProps)(ContactContainer);
