const data = [
    {
        id: "0",
        naziv_hr: "Govornica",
        naziv_en: "PHONE-BOOTH",
        tip_hr: "Kratkometražni film",
        tip_en: "Short movie",
        naslovna_slika: "govornica_title.jpg",
        main_video: "govornica.webm",
        podnaslov_hr: "",
        podnaslov_en: "",
        naslov_1_hr: "",
        naslov_1_en: "",
        tekst_1_hr: [
            "SINOPSIS: Kratkometražni film “Govornica” prikazuje komunikaciju dvoje ljudi koji su postali dio neobjašnjivog događaja unutar vremenske petlje. Njihova saznanja jedno o drugome pokreću niz događaja koji će utjecati na njihovu prošlost, sadašnjost i budućnost",
            "Žanr: SF drama",
        ],
        tekst_1_en: [
            "SYNOPSIS: Short feature film “Govornica” displays the communication of two people who have become part of a time anomaly. Their findings of one another set in motion a set of events which affect their past, present and the future.",
            "Genre: SF drama",
        ],
        slika_1: "govornica_1.jpg",
        naslov_2_hr: "",
        tekst_2_hr: [
            "Koncept priče je zamišljen kao minimalistički pristup putovanja kroz vrijeme, ali putem dijaloga i razgovora dvije osobe sa detaljima koji će povezati priču u cijelini.",
            "Kroz scenarij, cilj je bio prikazati arku likova u različitim stoljećima, gdje se prikazuje pogled na svijet iz obje perspektive.",
        ],
        slika_2: "govornica_2.jpg",
        naslov_2_en: "",
        tekst_2_en: [
            "Story is thought out to be as a minimalistic approach to space and time travel through dialogue of two strangers, and details which bring the whole story together.",
            "The idea was to show the connection of people in different centuries and with them, their unique world outlook.",
        ],
        naslov_3_hr: "",
        tekst_3_hr: [
            "Scenarij i režija: Dino Kos",
            "Produkcija: Edo Kalebić, Andrea Šikljan, Hela Žuljević",
            "Kamera: Leo Vitasović",
            "Glume: Frano Barunčić, Valentina Lončarić, Davor Jureško, Tijana Trošelj, Jasna Jeličić, Emir Mulalić, Andrea Špindel",
            "Glazba: Sandi Bratonja",
        ],
        slika_3: "govornica_3.jpg",
        naslov_3_en: "",
        tekst_3_en: [
            "Written and directed by: Dino Kos",
            "Produced by: Edo Kalebić, Andrea Šikljan, Hela Žuljević",
            "Camera: Leo Vitasović",
            "Starring: Frano Barunčić, Valentina Lončarić, Davor Jureško, Tijana Trošelj, Jasna Jeličić, Emir Mulalić, Andrea Špindel",
            "Music by: Sandi Bratonja",
        ],
        zakljucak_hr: "",
        zakljucak_en: "",
        galerija: "",
        video_src: "https://www.youtube.com/embed/rVn3CrlAHzs",
        poster: "govornica_poster.png",
    },
    {
        id: "1",
        naziv_hr: "Kameleon",
        naziv_en: "CHAMELEON",
        tip_hr: "Dugometražni film",
        tip_en: "Feature movie",
        naslovna_slika: "kameleon_title.jpg",
        main_video: "kameleon.webm",
        podnaslov_hr: "",
        podnaslov_en: "",
        naslov_1_hr: "",
        naslov_1_en: "",
        tekst_1_hr: [
            "SINOPSIS: Dugometražni film „Kameleon“ prikazuje dekonstrukciju modernog društva preko pojedinca koji nadilazi moralne norme i ograničenja te time uspjeva postati ideja veća od čovjeka koji umire i biva zaboravljen.",
            "Žanr: triler, psihološka drama, satira",
        ],
        tekst_1_en: [
            "SYNOPSIS: Feature film „Kameleon“ depicts the deconstruction of society through an individual who overcomes moral boundaries and limitations of a modern city. By doing so, he becomes an idea bigger than a regular man, whose death is often negligible.",
            "Genre – thriller, psychological drama, satire",
        ],
        slika_1: "kameleon_1.jpg",
        naslov_2_hr: "",
        naslov_2_en: "",
        tekst_2_hr: [
            "Na projektu je sudjelovao veći tim ljudi. Uz financijsku podršku Studentskog kulturnog centra Rijeka, film je napisan, snimljen i montiran u periodu od 7 mjeseci.",
            "“Kameleon” je svoja prikazivanja imao u Kino Sloboda Lovran, Art-kino Rijeka, Kino Tuškanac u Zagrebu i Pula Film Festival.",
        ],
        tekst_2_en: [
            "Team of a dozen people worked on the film, students of University of Rijeka and film professionals, who shared similar interests and ambition. With the financial support of SKC - film was written, shot and edited in a period of 7 months.",
            "The film was screened at Kino Sloboda Lovran, Art-kino Rijeka, Kino Tuškanac u Zagrebu and Pula Film Festival.",
        ],
        slika_2: "kameleon_2.png",
        naslov_3_hr: "",
        naslov_3_en: "",
        tekst_3_hr: [
            "Scenarij i režija: Dino Kos i Frano Barunčić",
            "Kamera: Luka Radiković",
            "Glume: Frano Barunčić, Tena-Antonija Torjanac, Katia Kuharić, Tomislav    Vičević, Jasna Jeličić, Nikola Nedić, Emir Mulalić, Anja Sabol, Lovro Ivanković.",
            "Glazba: Sandi Bratonja, Kamera: Luka Radiković",
        ],
        tekst_3_en: [
            "Written and directed by: Dino Kos i Frano Barunčić",
            "Cinematography: Luka Radiković",
            "Starring: Frano Barunčić, Tena-Antonija Torjanac, Katia Kuharić, Tomislav Vičević, Jasna Jeličić, Nikola Nedić, Emir Mulalić, Anja Sabol, Lovro Ivanković.",
            "Music: Sandi Bratonja",
        ],
        slika_3: "kameleon_3.png",
        zakljucak_hr: "",
        zakljucak_en: "",
        galerija: "",
        video_src: "https://www.youtube.com/embed/0xAMCvqxW7M",
        poster: "kameleon_poster.png",
    },
    {
        id: "2",
        naziv_hr: "EKAM - RIJEČI",
        naziv_en: "EKAM - WORDS",
        tip_hr: "Video spot",
        tip_en: "Music video",
        naslovna_slika: "ekam_title.jpg",
        main_video: "ekam.webm",
        podnaslov_hr: "",
        podnaslov_en: "",
        naslov_1_hr: "",
        naslov_1_en: "",
        tekst_1_hr: [
            "Video projekt predstavljen kao spot za grupu „EkAm“ koji prikazuje putovanje kroz stop motion animaciju. Pratite glazbu u ulozi „glavnog lika“, koja služi kao oblik naracije. Od piramida i labirinta, pa sve do planeta koji su ponekad točno postavljeni da stvore nešto s otkucajem srca.",
        ],
        tekst_1_en: [
            "A video project presented as a short film, showing the journey through stop motion animation. Follow the music as the main character which serves as a form of narration. From the pyramids and pinball maze, all the way to the planets that are sometimes just precisely placed to create something with a heartbeat.",
        ],
        slika_1: "ekam_1.jpg",
        naslov_2_hr: "",
        naslov_2_en: "",
        tekst_2_hr: [
            "Ovaj video projekt/spot predstavlja puno toga, ali glavna stvar koju smo imali na umu je to da video sam po sebi bude zabavan za gledati i da sa svakom novom scenom koju prikazujemo dobijemo drugačiji osjećaj.",
            "Snimano kroz period od 20 dana.",
        ],
        tekst_2_en: [
            "This video represents a lot, but the main thing we had in mind is that the video itself is fun to watch and that with each new scene we show there is a different feeling and story, which all culminates at the very end.",
            "Filmed over a period of 20 days. ",
        ],
        slika_2: "ekam_2.jpg",
        naslov_3_hr: "",
        naslov_3_en: "",
        tekst_3_hr: [
            "Scenarij i režija: Afrodita Lekaj i Dino Kos",
            "Kamera / rasvjeta: Pauilino Girotto",
            "Dizajn videa i glinenih modela: Afrodita Lekaj",
            "Glazba: EkAm",
        ],
        tekst_3_en: [
            "Directed by: Afrodita Lekaj i Dino Kos",
            "Camera / lighting by: Pauilino Girotto",
            "Video and clay model design by: Afrodita Lekaj",
            "Music: EkAm",
        ],
        slika_3: "ekam_3.jpg",
        zakljucak_hr: "",
        zakljucak_en: "",
        galerija: "",
        video_src: "https://www.youtube.com/embed/4m3bKwZZY80",
        poster: "ekam_poster.png",
    },
    {
        id: "3",
        naziv_hr: "MOPUS, DENI",
        naziv_en: "MOPUS, DENI",
        tip_hr: "Kratki film",
        tip_en: "Short movie",
        naslovna_slika: "mopus_title.jpg",
        main_video: "mopus.webm",
        podnaslov_hr: "",
        podnaslov_en: "",
        naslov_1_hr: "",
        naslov_1_en: "",
        tekst_1_hr: [
            "SINOPSIS: Što je stvarnost u momentima insomnije? Kratki igrani film „Mopus, Deni“ prati čovjeka u specifičnom momentu čudnih događaja i zvukova, koji utječu na njegovu paranoju i potragu za odgovorima.",
            "Žanr: triler, drama",
        ],
        tekst_1_en: [
            "SYNOPSIS: What is reality in moments of insomnia? The short feature film „Mopus, Deni“ follows a man in a specific moment of strange events and sounds, which affect his paranoia and the search for answers.",
            "Genre: thriller, drama",
        ],
        slika_1: "mopus_1.png",
        naslov_2_hr: "",
        naslov_2_en: "",
        tekst_2_hr: [
            "„Mopus, Deni“ je bio jedan od prvih projekata u kategoriji kratkih filmova koji smo napravili, gdje je cilj uvijek bio upoznati se sa ritmom i dinamikom seta i samog snimanja, a u isto vrijeme probati snimiti što zanimljiviju priču.",
            "Film je svoja prikazivanja imao u Art-kino Rijeka.",
        ],
        tekst_2_en: [
            "„Mopus, Deni“ was one of the first projects in the category of short films we made, where the goal was always to get acquainted with the rhythm and dynamics of the set and the shooting itself, and at the same time try to shoot as interesting a story as possible.",
            "The film had its screenings at the Rijeka Art Cinema.",
        ],
        slika_2: "mopus_2.png",
        naslov_3_hr: "",
        naslov_3_en: "",
        tekst_3_hr: [
            "Režija: Davor Zupičić i Dino Kos",
            "Scenarij: Dino Kos",
            "Glume: Jurica Marčec, Mirta Polanović, Frano Braun, Lovro Ivanković, Hela Žuljević",
            "Glazba: Sandi Bratonja",
        ],
        tekst_3_en: [
            "Directed by: Davor Zupičić i Dino Kos",
            "Screenplay: Dino Kos",
            "Starring: Jurica Marčec, Mirta Polanović, Frano Braun, Lovro Ivanković, Hela Žuljević",
            "Music by: Sandi Bratonja",
        ],
        slika_3: "mopus_3.png",
        zakljucak_hr: "",
        zakljucak_en: "",
        galerija: "",
        video_src: "https://www.youtube.com/embed/DvQZrzEl6ow",
        poster: "mopus_poster.png",
    },
];

module.exports = data;
