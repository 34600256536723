import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Transition } from "react-transition-group";
import NavItem from "./NavItem.jsx";
import overflowBody from "../utils/overflowBody";

const MobileHeader = ({ langData, activeLang, location }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleMenu = () => {
		setIsModalOpen(!isModalOpen);
		overflowBody(!isModalOpen);
	};

	const navItems = langData.items.map((val, key) => (
		<div className="mh-nav-item" key={key} onClick={toggleMenu}>
			<NavItem title={val.title} link={val.link} />
		</div>
	));

	const langItems = langData.langItems.map((val, key) => {
		const activeClass =
			activeLang === val.lang ? "header-lang-item--active" : "";
		return (
			<a
				href={`${val.link}${location.pathname}`}
				className={`header-lang-item ${activeClass}`}
				key={key}
			>
				<span className="paragraph header-lang-item__text">{val.lang}</span>
			</a>
		);
	});

	const menuActiveClass = isModalOpen ? "mh-menu--active" : "";
	const hamburgerActiveClass = isModalOpen ? "mh-hamburger--active" : "";

	const modalStyles = {
		transition: "opacity 300ms",
		opacity: 0,
	};

	const transitionStyles = {
		entering: {
			opacity: 0,
		},
		entered: {
			opacity: 1,
			transform: "translateX(0)",
		},
	};

	return (
		<div className="mh-container">
			<div className="mh-hamburger-container">
				<div
					className={`mh-hamburger ${hamburgerActiveClass}`}
					onClick={toggleMenu}
				>
					<div className="mh-hamburger-line"></div>
					<div className="mh-hamburger-line"></div>
					<div className="mh-hamburger-line"></div>
				</div>
			</div>
			<div className="mh-logo">
				<Link to="/">
					<img
						className="mh-logo-img"
						src="/images/icons/Flat22production_logo.svg"
						alt="logo"
					/>
				</Link>
			</div>
			<div className="header-lang">{langItems}</div>
			<Transition
				in={isModalOpen}
				timeout={{
					enter: 50,
					exit: 50,
				}}
				mountOnEnter
				unmountOnExit
			>
				{(state) => (
					<div
						className={`mh-menu ${menuActiveClass}`}
						style={{
							...modalStyles,
							...transitionStyles[state],
						}}
					>
						<div className="mh-nav-items">
							{navItems}
							<div className="header-lang-mobile">{langItems}</div>
						</div>
					</div>
				)}
			</Transition>
		</div>
	);
};

const mapStateToProps = (state) => ({
	langData: state.langData.header,
	activeLang: state.activeLang,
});

export default withRouter(connect(mapStateToProps)(MobileHeader));
