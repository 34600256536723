import React from "react";
import { Helmet } from "react-helmet";
import ScrollToTop from "../utils/ScrollToTop.jsx";
import OtherReferences from "../containers/OtherReferences.jsx";

const OtherReferencesRoute = () => {
    return (
        <div>
            <ScrollToTop />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Other references | FLAT22 production</title>
                <meta
                    name="og:description"
                    content="FLAT22 je video i film produkcija sa sjedištem u Rijeci."
                />
                <meta
                    property="og:image"
                    content="https://flat22production.com/backgrounds/flat22_large_logo.jpg"
                />
                <link href="https://flat22production.com/about" />
            </Helmet>
            <OtherReferences />
        </div>
    );
};

export default OtherReferencesRoute;
