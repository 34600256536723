import React from "react";
import { connect } from "react-redux";
import TitleTextLink from "../components/TitleTextLink.jsx";

const LandingTtlAbout = (props) => (
	<div id="about">
		<TitleTextLink langData={props.langData} />
	</div>
);

const mapStateToProps = (state) => ({
	langData: state.langData.landingTTLAbout,
});

export default connect(mapStateToProps)(LandingTtlAbout);
