import React from "react";
import { Helmet } from "react-helmet";
import LandingVideo from "../containers/LandingVideo.jsx";
import LandingTtlAbout from "../containers/LandingTtlAbout.jsx";
import References from "../containers/References.jsx";
import Services from "../containers/Services.jsx";
import LandingTtlContact from "../containers/LandingTtlContact.jsx";

const LandingRoute = () => (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="og:title" content="FLAT22 production" />
            <title>FLAT22 production</title>
            <meta
                name="og:description"
                content="FLAT22 je video i film produkcija sa sjedištem u Rijeci."
            />
            <meta
                property="og:image"
                content="https://flat22production.com/backgrounds/flat22_large_logo.jpg"
            />
            <link href="https://flat22production.com" />
        </Helmet>
        <LandingVideo />
        <LandingTtlAbout />
        <References />
        <Services />
        <div id="kontakt"></div>
        <LandingTtlContact />
    </div>
);

export default LandingRoute;
