import React from "react";
import { withRouter } from "react-router";
import AnimatedTitle from "../components/AnimatedTitle.jsx";
import Button from "./Button.jsx";

const TitleTextLink = ({ langData, history }) => (
	<div className="ttl">
		<AnimatedTitle title={langData.title} />
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<p className="paragraph ttl-paragraph">{langData.text}</p>
					<Button
						className="ttl-button"
						title={langData.btnTitle}
						handler={() => history.push(langData.btnLink)}
					/>
				</div>
			</div>
		</div>
	</div>
);

export default withRouter(TitleTextLink);
