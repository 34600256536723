import React from "react";
import { connect } from "react-redux";
import ContactContainer from "../containers/ContactContainer.jsx";

const LandingTtlContact = (props) => (
	<div>
		<ContactContainer />
	</div>
);

const mapStateToProps = (state) => ({
	langData: state.langData.landingTTLContact,
});

export default connect(mapStateToProps)(LandingTtlContact);
