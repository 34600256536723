import langData from "../data/langData";

export default (state = langData.hr, action) => {
	switch (action.type) {
		case "SET_ACTIVE_LANG":
			return langData[action.payload];
		default:
			return state;
	}
};
