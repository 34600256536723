import React from 'react'
import { HashLink } from 'react-router-hash-link'

const NavItem = props => (
    <HashLink
        className="nav-item"
        to={props.link}
        smooth
    >
        <span className="nav-item__title">
            {props.title}
        </span>
    </HashLink>
)

NavItem.props = {
    title: '',
    link: ''
}

export default NavItem
