import React, { Component } from "react";
import Imageloader from "alt-imageloader";
import Carousel, { Modal, ModalGateway } from "react-images";

class ReferenceGallery extends Component {
	static defaultProps = {
		data: "",
	};

	state = {
		isModalOpen: false,
		activeImgIndex: 0,
		data: [],
	};

	componentDidMount() {
		this.setState({
			data: this.props.data.split(","),
		});
	}

	openModal = (id) => {
		this.setState({
			isModalOpen: true,
			activeImgIndex: id,
		});
	};

	closeModal = () => {
		this.setState({
			isModalOpen: false,
			activeImgIndex: 0,
		});
	};

	render() {
		const imageSources = this.state.data.map((val) => ({
			source: `/images/backgrounds/${val}`,
		}));

		const images = this.state.data.map((val, key) => (
			<div className="col-md-4 col-sm-6 col-xs-12" key={key}>
				<div className="rg-image" onClick={this.openModal.bind(this, key)}>
					<div className="rg-image-inner">
						<Imageloader src={`/images/backgrounds/${val}`} />
					</div>
				</div>
			</div>
		));

		return (
			<div className="container">
				<div className="row">
					{images}
					<ModalGateway>
						{this.state.isModalOpen ? (
							<Modal onClose={this.closeModal}>
								<Carousel
									currentIndex={this.state.activeImgIndex}
									views={imageSources}
								/>
							</Modal>
						) : null}
					</ModalGateway>
				</div>
			</div>
		);
	}
}

export default ReferenceGallery;
