import React from 'react'

const NewInput = (props) => {
    const activeClass = props.isActive ? 'nin-inner--active' : ''

    return (
        <div className="nin-container">
            <div className={`nin-inner ${activeClass}`}>
                <input
                    className="nin-input"
                    onChange={props.onChangeHandler}
                />
                <div className="nin-border"></div>
                <div className="nin-placeholder">{props.placeholder}</div>
            </div>
        </div>
    )
}

NewInput.defaultProps = {
    placeholder: '',
    inputName: '',
    isActive: false,
    onChangeHandler: () => {}
}

export default NewInput
