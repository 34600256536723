import React from "react";
import { connect } from "react-redux";
import { musicVideos, videoEditing } from "../data/otherReferencesData";

const OtherReferences = ({ langData }) => {
    const renderReferences = (data) =>
        data.map((item) => {
            return (
                <div className="other-references-item" key={data.video_src}>
                    <h3 className="small-title">{item.title}</h3>
                    <div className="rc-youtube">
                        <iframe
                            width="560"
                            height="315"
                            src={item.video_src}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            );
        });

    return (
        <div className="section-spacing">
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <h1 className="about-title">{langData.title1}</h1>
                    </div>
                    <div className="col-md-8 col-md-offset-1">
                        {renderReferences(musicVideos)}
                    </div>
                    <div className="col-md-9">
                        <h1 className="about-title">{langData.title2}</h1>
                    </div>
                    <div className="col-md-8 col-md-offset-1">
                        {renderReferences(videoEditing)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    langData: state.langData.otherReferences,
});

export default connect(mapStateToProps)(OtherReferences);
