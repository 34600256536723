import React from "react";

const Button = (props) => (
	<div
		className={`aion-button ${props.className}`}
		onClick={props.handler.bind(this)}
	>
		<span className="ab__title">{props.title}</span>
	</div>
);

Button.defaultProps = {
	title: "",
	handler: () => {},
};

export default Button;
