export const musicVideos = [
	{
		title: "Sick Sheets - Allergies",
		video_src: "https://www.youtube.com/embed/-K1-tSPiFk4",
	},
	{
		title: "Markovski & Ivana Husar Mlinac - Kad nađeš put",
		video_src: "https://www.youtube.com/embed/cxFtWlnuwoo",
	},
	{
		title: "Sandi Bratonja - I to će proći",
		video_src: "https://www.youtube.com/embed/NgdvWaX1vos",
	},
	{
		title: "Histriatria - Chapter 1",
		video_src: "https://www.youtube.com/embed/go6UoAhiHCk",
	},
	{
		title: "Histriatria - Chapter 2",
		video_src: "https://www.youtube.com/embed/3qvVz53PcZM",
	},
];

export const videoEditing = [
	{
		title: "CINEMA 2020 - A Year In Film",
		video_src: "https://www.youtube.com/embed/FKPPFSjdupM",
	},
	{
		title: "CINEMA EDIT 2019",
		video_src: "https://www.youtube.com/embed/Fd3Fg_54UkE",
	},
	{
		title: "2018 IN FILM - A Retrospective",
		video_src: "https://www.youtube.com/embed/5mmMmBxhosI",
	},
	{
		title: "OSCARS 2019 - Best Picture Nominees",
		video_src: "https://www.youtube.com/embed/Y1HFL4TinEY",
	},
	{
		title: "CUT TO: The Films of Zack Snyder",
		video_src: "https://www.youtube.com/embed/WLLvVE1OMAE",
	},
];
