import React, { Component } from "react";
import { connect } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import AnimatedTitle from "../components/AnimatedTitle.jsx";

class Services extends Component {
	state = {
		animate: false,
	};

	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState({
				animate: true,
			});
		}
	};

	render() {
		const items = this.props.langData.items.map((val, key) => {
			const animClass = `services-item--anim-${key}`;

			return (
				<div className={`services-item ${animClass}`} key={key}>
					<img
						className="services-item-img"
						src={`/images/icons/${val.img}`}
						alt={val.title}
					/>
					<h2 className="tiny-title services-item__title">{val.title}</h2>
				</div>
			);
		});

		return (
			<div id="usluge" className="services">
				<AnimatedTitle title={this.props.langData.title} />
				<VisibilitySensor
					onChange={this.onVisibilityChange.bind(this)}
					offset={{ top: 200 }}
					partialVisibility
				>
					<div className="container">
						<div className={`row ${this.state.animate ? "row-animate" : ""}`}>
							<div className="col-md-10 col-md-offset-1">
								<div className="services-items">{items}</div>
							</div>
						</div>
					</div>
				</VisibilitySensor>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	langData: state.langData.services,
});

export default connect(mapStateToProps)(Services);
