const langData = {
	hr: {
		header: {
			items: [
				{
					title: "O nama",
					link: "/about",
				},
				{
					title: "Reference",
					link: "/#reference",
				},
				{
					title: "Usluge",
					link: "/#usluge",
				},
				{
					title: "Kontakt",
					link: "/#kontakt",
				},
			],
			langItems: [
				{
					lang: "hr",
					link: "",
				},
				{
					lang: "en",
					link: "/en",
				},
			],
		},
		footer: {
			navItems: [
				{
					title: "O nama",
					link: "/about",
				},
				{
					title: "Reference",
					link: "/#reference",
				},
				{
					title: "Usluge",
					link: "/#usluge",
				},
			],
			copyright: "Copyright Flat22 produkcija 2022. All Rights Reserved.",
		},
		landingTTLAbout: {
			title: "FLAT22 production",
			text: "FLAT22 je video i film produkcija sa sjedištem u Rijeci. Za naše klijente pokrivamo sva područja uspješno izrađenog filmskog ili video projekta na jednom mjestu, od predprodukcije, snimanja  i video montaže. Naš rad do sada uključivao je filmove, video spotove, evente, edukativne video projekte, reklame i razne online video materijale. Priča i imidž svakoga klijenta je autentična, upravo iz toga razloga posvećujemo vrijeme svakome klijentu individualno i razumijemo važnost priče i kreativnog izražavanja kako bi naše klijente odvojili od ostalih.",
			btnTitle: "O nama",
			btnLink: "/about",
		},
		references: {
			title: "Reference",
			btnTitle: "Ostale reference",
			btnLink: "/other-references",
		},
		otherReferences: {
			title1: "Video spotovi",
			title2: "Video montaže",
		},
		services: {
			title: "Usluge",
			items: [
				{
					title: "Filmovi",
					img: "flat22_service_5.svg",
				},
				{
					title: "Pisanje scenarija",
					img: "flat22_service_4.svg",
				},
				// {
				// 	title: "Fotografija",
				// 	img: "flat22_service_6.svg",
				// },
				{
					title: "Video montaže",
					img: "flat22_service_7.svg",
				},
				{
					title: "Reklame",
					img: "flat22_service_8.svg",
				},
				{
					title: "Video spotovi",
					img: "flat22_service_9.svg",
				},
				{
					title: "Makeup",
					img: "flat22_service_3.svg",
				},
				{
					title: "Costume design",
					img: "flat22_service_2.svg",
				},
			],
		},
		referenceContainer: {
			title: "Kreativni proces",
		},
		contact: {
			title: "Kontaktiraj nas",
			address: "Franje Čandeka 23b, 51000 Rijeka",
			email: "flat22production@gmail.com",
			phoneNum: "+385 91 1776 747",
			followUs: "Pratite nas",
			namePlaceH: "Ime i prezime",
			emailPlaceH: "Email",
			messagePlaceH: "Poruka",
			btnTitle: "Pošalji",
			messages: [
				"Poruka uspješno poslana",
				"Došlo je do pogreške pri slanju. Pokušajte ponovo.",
				"Molimo unesite podatke u sva polja",
				"Molimo unesite ispravan email",
			],
		},
		scroller: {
			title: "kreni dalje",
		},
		about: {
			title:
				"Pokrivamo svaku fazu Vaše ideje, od pre-produkcije, snimanja i video montaže.",
			text1:
				"FLAT22 je video i film produkcija sa sjedištem u Rijeci. Za naše klijente pokrivamo sva područja uspješno izrađenog filmskog ili video projekta na jednom mjestu, od predprodukcije, snimanja  i video montaže. Naš rad do sada uključivao je filmove, video spotove, evente, edukativne video projekte, reklame i razne online video materijale.",
			text2:
				"Naš tim iza sebe ima tri originalna i autorska djela: dva kratka filma, ‘’Govornica’’ (2017) i ‘’Mopus, Deni’’ (2015), dugometražni film ‘’Kameleon’’ (2017) koji je bio izabran i prikazan na Pula Film Festivalu 2018.godine. ",
			text3:
				"Priča i imidž svakoga klijenta je autentična, upravo iz toga razloga posvećujemo vrijeme svakome klijentu individualno i razumijemo važnost priče u bilo kojoj formi kroz kreativna riješenja kako bi naše klijente odvojili od ostalih. Sa svakim novim projektom, okupljamo tim umjetnika, profesionalaca i entuzijasta u ovom polju kako bi ostvarili još jedan uspješan projekt.",
			subtitle: "Upoznaj tim",
			team_member_1: [
				"U FLAT22 produkciji radim kao scenarist, redatelj i video montažer. Od rane dobi, glavni poriv je uvijek bio stvaranje novih priča koje se mogu israziti kroz video i film.",
				"Zadnjih 10 godina sam prošao kroz puno perspektiva oko samog filma, jedan od glavnih je bio rad kao filmski novinar i kritičar, gdje sam dobio dobar uvid iza kulisa i kako nastaju svakakve ideje i filmovi kroz intervjue s ljudima u industriji. Do sada sam režirao i napisao jedan dugometražni film, tri kratka igrana filma i pet video spotova.",
				"Uz kreaciju video projekata, pisanje scenarija i same produkcije, cilj je uvijek stvarati ideje i nastaviti kolaboraciju sa klijentima i njihovim vizijama.",
			],
			team_member_2: [
				"Dok sam posvećivala  svoje vrijeme radeći kao vizažist  na modnim editorialima i šivajući odjeću iz hobija, tražila sam više različitih načina i medija kako mogu izraziti svoju kreativnost Nije mi trebalo dugo da se nađem u svijetu video produkcije i snimanja, područjima na kojima ne samo da mogu koristiti svoje prethodne vještine kao što je spomenuto, već ih i dovesti na drugu razinu gdje su integrirane kao dio procesa za vizualni prikaz priče.",
				"Radeći na projektima moj interes prema filmskoj industriji je sada postao prioritet s fokusom na produkciju, pisanje scenarija, režiju, vizualan dizajn i ostalo.",
				"Uživam u potrazi za idejama, eksperimentiranju s različitim tehnikama i izazivanju sebe sa svakim projektom kako bih još više naučila i rasla kao kreativan pojedinac i pružila klijentu najbolji mogući rezultat u predstavljanju njegove priče, ideje, osjećaj ili emociju kojim se želi izraziti kroz vizualni medij.",
			],
		},
	},
	en: {
		header: {
			items: [
				{
					title: "About",
					link: "/about",
				},
				{
					title: "References",
					link: "/#reference",
				},
				{
					title: "Services",
					link: "/#usluge",
				},
				{
					title: "Contact",
					link: "/#kontakt",
				},
			],
			langItems: [
				{
					lang: "hr",
					link: "",
				},
				{
					lang: "en",
					link: "/en",
				},
			],
		},
		footer: {
			navItems: [
				{
					title: "About",
					link: "/about",
				},
				{
					title: "References",
					link: "/#reference",
				},
				{
					title: "Services",
					link: "/#usluge",
				},
			],
			copyright: "Copyright Flat22 produkcija 2022. All Rights Reserved.",
		},
		landingTTLAbout: {
			title: "FLAT22 production",
			text: "FLAT22 is a video and film production based in Rijeka, Croatia. For our clients, we cover all areas of a successfully made film or video project in one place, from pre-production, filming and video editing. Our work so far has included movies, videos, events, educational video projects, commercials and various online content. The story and image of each client is authentic, which is why we dedicate time to each client individually and understand the importance of storytelling and creative expression to set our clients apart from others.",
			btnTitle: "About",
			btnLink: "/about",
		},
		referenceContainer: {
			title: "Creative process",
		},
		contact: {
			title: "Contact us",
			address: "Franje Čandeka 23b, 51000 Rijeka",
			email: "flat22production@gmail.com",
			phoneNum: "+385 91 1776 747",
			namePlaceH: "Name and surname",
			emailPlaceH: "Email",
			messagePlaceH: "Message",
			btnTitle: "Send",
			messages: [
				"Message successfully sent",
				"There was an error. Please try again.",
				"Please enter data in all fields",
				"Please enter a valid email",
			],
		},
		references: {
			title: "References",
			btnTitle: "Other references",
			btnLink: "/other-references",
		},
		otherReferences: {
			title1: "Music Videos",
			title2: "Editing video projects",
		},
		services: {
			title: "Services",
			items: [
				{
					title: "Movies",
					img: "flat22_service_5.svg",
				},
				{
					title: "Screenwriting",
					img: "flat22_service_4.svg",
				},
				// {
				// 	title: "Photography",
				// 	img: "flat22_service_6.svg",
				// },
				{
					title: "Video editing",
					img: "flat22_service_7.svg",
				},
				{
					title: "Advertising",
					img: "flat22_service_8.svg",
				},
				{
					title: "Music videos",
					img: "flat22_service_9.svg",
				},
				{
					title: "Makeup",
					img: "flat22_service_3.svg",
				},
				{
					title: "Costume design",
					img: "flat22_service_2.svg",
				},
			],
		},
		scroller: {
			title: "scroll down",
		},
		about: {
			title:
				"We cover every part of your idea: pre-production, filming and editing.",
			text1:
				"FLATT22 is a film and video production team based in Rijeka, Croatia. For our clients, we cover every part of a successful video and film project in-house: pre-production, filming and post production.  Our work includes films, music videos, events, educational videos, commercials and various online content.",
			text2:
				"Until today our production has three original and authors’ work: two short films, ‘Phone Booth” (2017) and ‘’Mopus, Deni’’ (2015), as well as one feature length film ‘Chameleon (2017)  which was selected and screened at 2018 edition of Pula Film Festival.",
			text3:
				"Story and public image of every client is authentic and for that reason we devote time to each client individually. At FLAT22, we understand the importance of good story in any form through creative solutions so our clients can be separated from others. With each new project, we gather artists, professionals and enthusiasts from necessary art fields to create anoher successful project.",
			subtitle: "Meet The team",
			team_member_1: [
				"Working as a writer, video editor and director. Since early age, the main drive was creating new stories that can be expressed through video and film.",
				"As for the industry itself, I’ve been in and around it for the last 10 years, doing a lot of film-related reporting and reviews as a news editor, which gave me a lot of knowledge and insights into stories and motion pictures.",
				"Until now, I’ve directed a feature film, three short films and five music videos. Along with creating videos, writing screenplays and doing the production work, the goal is always to develop ideas and continue to collaborate on projects with new clients and their visions.",
			],
			team_member_2: [
				"As I devoted my time working as a makeup artist for fashion editorials and sewing clothes for a hobby, I looked for more different ways to express my creativity. It didn’t take me long time to find myself in the world of video production where I could incorporate my previous skills into a film story.",
				"By working on projects my interest in the film industry has now become a priority with a focus on production, screenwriting, directing, visual design and more.",
				"I enjoy searching for ideas, experimenting with different techniques and challenging myself with each project to learn and grow even more as a creative individual and also give the client the best possible result in presenting his story, idea, feeling or emotion he wants to express through this visual medium.",
			],
		},
	},
};

export default langData;
