import React from 'react'

const Input = props => (
    <div className={`input-item ${props.containerClass}`}>
        <input
            className="input-item__input"
            placeholder={props.placeholder}
            onChange={props.handleMailInput.bind(null, props.inputKey)}
        />
        <div className="input-item__border"></div>
    </div>
)

Input.defaultProps = {
    placeholder: '',
    inputKey: '',
    containerClass: '',
    inputClass: '',
    handleMailInput: () => {}
}

export default Input
